import { AUTH_IMPERSONATE_USER_ID_ROUTE_NAME, LOGIN_ROUTE_NAME } from '~/helpers/route'
import { IMPERSONATE_USER_ID_LS_ALIAS } from '~/helpers/common'
import { setLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'

export default async function ({
  route = {},
  $auth = {},
  redirect = () => {},
  $socket = {},
  store = {}
}) {
  if (route?.name === AUTH_IMPERSONATE_USER_ID_ROUTE_NAME) {
    const impersonateUserId = route?.params?.id ?? null
    const practiceLocationId = route?.query?.pl
    if (impersonateUserId) {
      setLocalStorage({
        name: IMPERSONATE_USER_ID_LS_ALIAS,
        data: parseInt(impersonateUserId),
      })
      if (practiceLocationId) {
        store.commit('user/SET_CURRENT_PRACTICE_LOCATION_ID', practiceLocationId)
        setLocalStorage({ name: 'currentPracticeLocationId', data: practiceLocationId })
      }
      if (!$auth?.loggedIn) {
        redirect(`/${LOGIN_ROUTE_NAME}`)
        return false
      } else {
        $socket.init(impersonateUserId)
        await $auth.fetchUser()
        redirect('/')
      }
    }
  }
}
