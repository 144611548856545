import { render, staticRenderFns } from "./registration.vue?vue&type=template&id=ce5a1142&"
import script from "./registration.vue?vue&type=script&lang=js&"
export * from "./registration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FullScreenLoader: require('/home/runner/work/ZenOne-Consumer-Portal/ZenOne-Consumer-Portal/components/FullScreenLoader/index.vue').default,Logo: require('/home/runner/work/ZenOne-Consumer-Portal/ZenOne-Consumer-Portal/components/logo/index.vue').default})
