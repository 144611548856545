import actions from './actions'
import mutations from './mutations'
import { isNull } from '~/ZenOne-StoryBook/helpers/types'
export const DEFAULT_PAGE_NUMBER = 1
export const DEFAULT_PAGE_SIZE = 35

export const YEAR_AGO = new Date()
YEAR_AGO.setDate(YEAR_AGO.getDate() - 365)
export const NINETY_DAYS_AGO = new Date()
NINETY_DAYS_AGO.setDate(NINETY_DAYS_AGO.getDate() - 90)

export const DEFAULT_FILTERS = {
  dateFrom: YEAR_AGO,
  dateTo: null,
  distributorId: [],
  search: '',
  orderId: null,
  archived: false,
}

export function isDefaultFilters(filters) {
  return (
    isNull(filters.orderId) &&
    filters.search === '' &&
    Array.isArray(filters.distributorId) &&
    filters.distributorId.length === 0 &&
    filters.dateTo === null &&
    filters.dateFrom?.getTime() === DEFAULT_FILTERS.dateFrom.getTime()
  )
}

const manageOrders = {
  state: {
    availableDistributors: null, // null indicates loading state
    availableDistributorsPaymentHistory: null, // null indicates loading state
    filters: DEFAULT_FILTERS,
    byOrderFilterOptions: [],
  },
  getters: {
    filters: (state) => state.filters,
    orderIdFilterValue: (state, getters) => getters.filters?.orderId,
    byOrderFilterOptions: (state) => state.byOrderFilterOptions,
    availableDistributors: (state) => state.availableDistributors,
    availableDistributorsPaymentHistory: (state) =>
      state.availableDistributorsPaymentHistory,
  },
  mutations,
  actions,
  namespaced: true,
}

export default manageOrders
