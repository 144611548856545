
import { mapGetters, mapMutations } from 'vuex'
import iconCard from '~/components/img/icon-card.png'
import iconCard2 from '~/components/img/icon-card2.png'
import { api, getJsonApiHeaders } from '~/config/api'
import storage from '~/ZenOne-StoryBook/mixins/localStorage.js'
import { getRefreshedPracticeLocation } from '~/helpers/practiceLocation'

export default {
  name: 'DialogPaymentInfo',
  mixins: [storage],
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconCard,
      iconCard2,
      error: false,
      loadingCardElement: false,
      loading: false,
      intervalId: null,
      // ---- //
      style: {
        base: {
          iconColor: '#000',
          color: '#000',
          fontWeight: '800',
          fontFamily: 'Press Start 2P',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: '#fce883',
          },
          '::placeholder': {
            color: '#abb7c3',
          },
        },
        invalid: {
          iconColor: '#FFC7EE',
          color: 'red',
        },
      },
      stripeCustomer: null,
      clientSecret: null,
      card: null,
    }
  },
  computed: {
    ...mapGetters({
      practiceLocationId: 'user/practiceLocationId',
    }),
  },
  watch: {
    dialog(value) {
      if (value) {
        this.intervalId = setInterval(() => {
          const cardElement = document.querySelector('#card-element')
          if (cardElement) {
            this.onStripeElements()
            this.stopTimer()
          }
        }, 300)
      }
    },
  },
  beforeDestroy() {
    this.stopTimer()
  },
  async mounted() {
    this.onStripeElements()
    if (this.practiceLocationId) {
      await this.onPracticeLocation()
    }
  },
  methods: {
    ...mapMutations('user', {
      setCard: 'SET_CARD',
    }),
    stopTimer() {
      clearInterval(this.intervalId)
      this.intervalId = null
    },
    onClose() {
      this.$emit('onCloseDialogPaymentInfo')
    },
    async onPracticeLocation() {
      try {
        this.loading = true
        const practiceLocation = await getRefreshedPracticeLocation(this.$store)

        if (!practiceLocation.stripeCustomer) {
          await this.addToStripeCustomer()
        } else {
          this.stripeCustomer = practiceLocation.stripeCustomer
        }
        await this.onClientSecret()
      } catch (e) {
        console.error('DialogPaymentInfo onPracticeLocation', e)
      } finally {
        this.loading = false
      }
    },
    async addToStripeCustomer() {
      try {
        this.loading = true
        const res = await this.$store.dispatch('jvZen/get', [
          `${api.PRACTICE_LOCATIONS}/${this.practiceLocationId}/${api.ADD_TO_STRIPE}`,
          getJsonApiHeaders(),
        ])
        this.stripeCustomer = res?.data?.stripeCustomer ?? null
      } catch (e) {
        console.error('onAddToStripeCustomer', e)
      } finally {
        this.loading = false
      }
    },
    async onClientSecret() {
      try {
        const res = await this.$store.dispatch('jvZen/get', [
          `${api.PRACTICE_LOCATIONS}/${this.practiceLocationId}/${api.CREATE_SETUP_INTENT}`,
          getJsonApiHeaders(),
        ])
        this.clientSecret = res?.data?.client_secret ?? null
      } catch (e) {
        console.error('DialogPaymentInfo onClientSecret', e)
      }
    },
    onStripeElements() {
      try {
        if (this.$stripe) {
          const elements = this.$stripe.elements(this.clientSecret)
          const card = elements.create('card', { style: this.style, disableLink: true })
          this.card = card
          card.mount('#card-element')
        }
      } catch (e) {
        console.error('DialogPaymentInfo onStripeElements', e)
      }
    },
    async onAddCard() {
      try {
        this.loading = true
        this.error = false
        const createPaymentMethod = await this.$stripe.createPaymentMethod({
          type: 'card',
          card: this.card,
        })
        if (!createPaymentMethod?.paymentMethod?.card)
          throw new Error('Add Card error (on the Stripe side)')

        const practiceLocation = await getRefreshedPracticeLocation(this.$store)

        await this.$axios.post(
          `${api.PRACTICE_LOCATIONS}/${practiceLocation.id}/${api.ATTACH_PAYMENT_METHOD}`,
          {
            data: {
              type: 'practice-locations',
              id: practiceLocation.id,
              attributes: {
                paymentMethodId: createPaymentMethod.paymentMethod.id,
              },
            },
          }
        )

        const data = {
          number: createPaymentMethod.paymentMethod.card.last4,
          paySystem: createPaymentMethod.paymentMethod.card.brand,
          date: `${createPaymentMethod.paymentMethod.card.exp_month}/${createPaymentMethod.paymentMethod.card.exp_year}`,
          postalCode: createPaymentMethod.paymentMethod.billing_details.address.postal_code
        }

        const payload = {
          params: {
            ...practiceLocation.params,
            card: data,
          },
          _jv: {
            id: practiceLocation.id.toString(),
            type: `${api.PRACTICE_LOCATIONS}`
          }
        }
        await this.$store.dispatch('jvZen/patch', [
          payload,
          getJsonApiHeaders()
        ])

        this.setCard(data)
        this.$emit('onCloseDialogPaymentInfo')
        this.$emit('onAddCard')
      } catch (e) {
        console.error('DialogPaymentInfo add card is failed: ', e)
        this.$emit('onAddCardFailed')
        this.error = true
      } finally {
        this.loading = false
      }
    },
  },
}
