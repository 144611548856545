export default {
  methods: {
    removeLocalStorageItem(key, session= false) {
      return session ? sessionStorage.removeItem(key) : localStorage.removeItem(key)
    },
    setLocalStorage(e, session= false) {
      return session ? sessionStorage.setItem(e.name, JSON.stringify(e.data)) : localStorage.setItem(e.name, JSON.stringify(e.data))
    },
    getLocalStorage(key, session = false) {
      if (key.length) {
        return JSON.parse(session ? sessionStorage.getItem(key) : localStorage.getItem(key));
      } else {
        return false
      }
    },
  },
}
