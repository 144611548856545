export default ({ app }) => {
  if (process.server) return

  const USERFLOW_SCRIPT_URL = 'https://js.userflow.com/userflow.js'
  const USERFLOW_TOKEN = 'ct_irry3vu7xnbjpbgbzdik25743q'

  const loadScript = (src) =>
    new Promise((resolve, reject) => {
      const existing = document.querySelector(`script[src="${src}"]`)
      if (existing) return resolve()

      const script = document.createElement('script')
      script.src = src
      script.async = true
      script.onload = resolve
      script.onerror = reject
      document.head.appendChild(script)
    })

  loadScript(USERFLOW_SCRIPT_URL).then(() => {
    if (typeof userflow === 'undefined') {
      console.warn('Userflow failed to load')
      return
    }

    userflow.init(USERFLOW_TOKEN)

    const interval = setInterval(() => {
      const auth = app.$auth
      const user = auth?.user

      if (
        auth?.loggedIn &&
        user &&
        typeof userflow.identify === 'function' &&
        !window.__userflowIdentified
      ) {
        console.log('[Userflow] Identifying with real API:', user)

        userflow.identify(user.id, {
          name: user.name,
          email: user.email
        })

        window.__userflowIdentified = true
        clearInterval(interval)
      }
    }, 1000)
  }).catch((err) => {
    console.error('Failed to load Userflow script', err)
  })
}
