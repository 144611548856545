import {
  AUTH_IMPERSONATE_USER_ID_ROUTE_NAME,
  EMAIL_CONFIRMATION_ID_ROUTE_NAME,
  REGISTRATION_ROUTE_NAME
} from '~/helpers/route'
import { isGroupSubDomain } from '~/helpers/user'

export default function({ $auth, route, redirect }) {
  try {
    const needToBeCompleted = ![
      REGISTRATION_ROUTE_NAME,
      AUTH_IMPERSONATE_USER_ID_ROUTE_NAME,
      EMAIL_CONFIRMATION_ID_ROUTE_NAME
    ].includes(route.name)

    if (!$auth.loggedIn) {
      return false
    }

    if ($auth.user.emailConfirmed && $auth.user.practiceLocation.wizardCompleted) {
      if (isGroupSubDomain())
        return redirect('https://my.zenone.com')
      if (route.name === REGISTRATION_ROUTE_NAME)
        return redirect({name: 'index'})
    } else if (needToBeCompleted) {
      return redirect('/registration')
    }
  } catch (e) {
    console.error(`Check wizard completion error: ${e}`)
  }
}
