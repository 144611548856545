import mutations from './mutations'
import actions from './actions'
const distributors = {
  state: {
    distributor: null,
    connectForm: {
      distributorId: null,
    },
    searchTerm: '',
  },
  getters: {
    getDistributor: (state) => state.distributor,
    connectForm: (state) => state.connectForm,
    searchTerm: (state) => state.searchTerm.toLowerCase(),
  },
  namespaced: true,
  mutations,
  actions,
}
export default distributors
